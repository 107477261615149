<template>
  <div class="messageShow" v-if="showMessage">
    <div class="messageBox">{{ message }}</div>
    <button class="btn btn-success buttonclass" @click="endMessage()">حفظ / save</button>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "showMessage",
  props: {
    message: String
  },
  data() {
    return {
      showMessage: true,
    }
  },
  methods:{
    async endMessage(){
      let pp = new FormData();
      let type = 'expireMessage';

      pp.append('data[doneIt]','yes');
      pp.append('type',type);
      pp.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
      const response = await axios.post(
          this.$store.state.SAMCOTEC.r_path
          ,pp
      )
      this.showMessage = false;
      this.$router.push({name: 'Home'})
    }
  }
}
</script>

<style scoped>
.messageShow{
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom: 0;
  display:flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  background: #FFFFFFDF;
  border:1px solid #000000;
  color:#000000;
}
.messageBox{
  display:flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  width:500px;
  height:250px;
}
.buttonclass{
  position:fixed;
  z-index: 1000;
  bottom: 50px;
  left:100px;
}
</style>